// @ts-nocheck
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import { Row } from 'react-bootstrap';
import 'animate.css/animate.min.css';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Title from '../components/Title';
import heroImage from '../images/locations-background.jpg';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: #074166;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  margin: 1em 0;
`;

const CountryTitle = styled.h3`
  color: #074166;
  letter-spacing: 2px;
  margin-bottom: 0.6em;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
`;

const City = styled(Link)`
  color: black;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 0.95em;
  display: block;
`;

const CityContainer = styled.div`
  @media (min-width: 992px) {
    min-height: 135px;
  }
`;

const Strike = styled.strong`
  font-weight: bold;
  text-decoration: line-through;
`;

const Special = styled.strong`
  font-size: 1.1em;
  color: #074166
`;


const CountryContainer = styled.div`
  margin-bottom: 2.5em;
  @media (min-width: 992px) {
    padding: 2em 1em;
    border-right: 2px solid #efefef;
    display: flex;
    flex-direction: column;
    &:last-child {
      border-right: none;
    }
  }
`;

// const Callout = styled.a`
//   && {
//     background: #185475;
//     border-color: #185475;
//     align-items: center;
//     padding: 0.7em 1em;
//     display: flex;
//     margin: 0em auto 1em auto;
//     text-transform: uppercase;
//     border-radius: 0px;
//     animation-delay: ${(props) => `${props.delay}s`};
//   }
//   && svg {
//     float: right;
//     margin-left: 0.5em;
//   }
// `;

const PricingLabel = styled.p`
  text-align: center;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: auto;
`;

const LocationsPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title="Locations" />
      <Wrapper className="container-fluid">
        <Title
          imageUrl={heroImage}
          position="top"
          text={intl.formatMessage({ id: 'locations.central.hero_text' })}
        />
        <Heading>
          {intl.formatMessage({ id: 'locations.central.title' })}
        </Heading>
        <Row className="justify-content-center">
          {/* <CountryContainer className="col-lg-2">
            <CountryTitle>
              {intl.formatMessage({ id: "locations.central.uk.title" })}
            </CountryTitle>
            <CityContainer>
              <City to="/locations/london">
                {intl.formatMessage({
                  id: "locations.central.uk.cities.london",
                })}
              </City>
              <City to="/locations/oxford">
                {intl.formatMessage({
                  id: "locations.central.uk.cities.oxford",
                })}
              </City>
              <City to="/locations/stratford">
                {intl.formatMessage({
                  id: "locations.central.uk.cities.stratford",
                })}
              </City>
            </CityContainer>
            <PricingLabel>
              {intl.formatMessage({ id: "locations.central.uk.fee" })}
              <br />
              <strong>
                {intl.formatMessage({ id: "locations.central.uk.price" })}
              </strong>
            </PricingLabel>
          </CountryContainer> */}
          <CountryContainer className="col-lg-2">
            <CountryTitle>
              {intl.formatMessage({
                id: 'locations.central.australia.title',
              })}
            </CountryTitle>
            <CityContainer>
              <City to="/locations/sydney">
                {intl.formatMessage({
                  id: 'locations.central.australia.cities.sydney',
                })}
              </City>
              <City to="/locations/melbourne">
                {intl.formatMessage({
                  id: 'locations.central.australia.cities.melbourne',
                })}
              </City>
              <City to="/locations/brisbane">
                {intl.formatMessage({
                  id: 'locations.central.australia.cities.brisbane',
                })}
              </City>
              <City to="/locations/gold-coast">
                {intl.formatMessage({
                  id: 'locations.central.australia.cities.goldcoast',
                })}
              </City>
              <City to="/locations/cairns">
                {intl.formatMessage({
                  id: 'locations.central.australia.cities.cairns',
                })}
              </City>
            </CityContainer>
            <PricingLabel>
              {intl.formatMessage({
                id: 'locations.central.australia.pricing.four_weeks.fee',
              })}
              <br />
              <Strike>
                {intl.formatMessage({
                  id: 'locations.central.australia.pricing.four_weeks.price',
                })}
              </Strike>
              <br />
              <Special>
                {intl.formatMessage({
                  id: 'locations.central.australia.pricing.four_weeks.special',
                })}
              </Special>
            </PricingLabel>
            <PricingLabel>
              {intl.formatMessage({
                id: 'locations.central.australia.pricing.five_weeks.fee',
              })}
              <br />
              <Strike>
                {intl.formatMessage({
                  id: 'locations.central.australia.pricing.five_weeks.price',
                })}
              </Strike>
              <br />
              <Special>
                {intl.formatMessage({
                  id: 'locations.central.australia.pricing.five_weeks.special',
                })}
              </Special>
            </PricingLabel>
          </CountryContainer>
          <CountryContainer className="col-lg-2">
            <CountryTitle>
              {intl.formatMessage({ id: 'locations.central.usa.title' })}
            </CountryTitle>
            <CityContainer>
              <City to="/locations/new-york">
                {intl.formatMessage({
                  id: 'locations.central.usa.cities.newyork',
                })}
              </City>
              <City to="/locations/boston">
                {intl.formatMessage({
                  id: 'locations.central.usa.cities.boston',
                })}
              </City>
              <City to="/locations/miami">
                {intl.formatMessage({
                  id: 'locations.central.usa.cities.miami',
                })}
              </City>
            </CityContainer>
            <PricingLabel>
              {intl.formatMessage({ id: 'locations.central.usa.pricing.four_weeks.fee' })}
              <br />
              <Strike>
                {intl.formatMessage({ id: 'locations.central.usa.pricing.four_weeks.price' })}
              </Strike>
              <br />
              <Special>
                {intl.formatMessage({ id: 'locations.central.usa.pricing.four_weeks.special' })}
              </Special>
            </PricingLabel>
            <PricingLabel>
              {intl.formatMessage({ id: 'locations.central.usa.pricing.five_weeks.fee' })}
              <br />
              <Strike>
                {intl.formatMessage({ id: 'locations.central.usa.pricing.five_weeks.price' })}
              </Strike>
              <br />
              <Special>
                {intl.formatMessage({ id: 'locations.central.usa.pricing.five_weeks.special' })}
              </Special>
            </PricingLabel>
          </CountryContainer>
          <CountryContainer className="col-lg-2">
            <CountryTitle>
              {intl.formatMessage({ id: 'locations.central.canada.title' })}
            </CountryTitle>
            <CityContainer>
              <City to="/locations/toronto">
                {intl.formatMessage({
                  id: 'locations.central.canada.cities.toronto',
                })}
              </City>
              <City to="/locations/vancouver">
                {intl.formatMessage({
                  id: 'locations.central.canada.cities.vancouver',
                })}
              </City>
              <City to="/locations/calgary">
                {intl.formatMessage({
                  id: 'locations.central.canada.cities.calgary',
                })}
              </City>
            </CityContainer>
            <PricingLabel>
              {intl.formatMessage({ id: 'locations.central.canada.pricing.four_weeks.fee' })}
              <br />
              <Strike>
                {intl.formatMessage({
                  id: 'locations.central.canada.pricing.four_weeks.price',
                })}
              </Strike>
              <br />
              <Special>
                {intl.formatMessage({
                  id: 'locations.central.canada.pricing.four_weeks.special',
                })}
              </Special>
            </PricingLabel>
            <PricingLabel>
              {intl.formatMessage({ id: 'locations.central.canada.pricing.five_weeks.fee' })}
              <br />
              <Strike>
                {intl.formatMessage({
                  id: 'locations.central.canada.pricing.five_weeks.price',
                })}
              </Strike>
              <br />
              <Special>
                {intl.formatMessage({
                  id: 'locations.central.canada.pricing.five_weeks.special',
                })}
              </Special>
            </PricingLabel>
          </CountryContainer>
          {/* <CountryContainer className="col-lg-2">
            <CountryTitle>
              {intl.formatMessage({
                id: "locations.central.ireland.title",
              })}
            </CountryTitle>
            <CityContainer>
              <City to="/locations/dublin">
                {intl.formatMessage({
                  id: "locations.central.ireland.cities.dublin",
                })}
              </City>
            </CityContainer>
            <PricingLabel>
              {intl.formatMessage({ id: "locations.central.ireland.fee" })}
              <br />
              <strong>
                {intl.formatMessage({
                  id: "locations.central.ireland.price",
                })}
              </strong>
            </PricingLabel>
          </CountryContainer> */}
        </Row>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(LocationsPage);
